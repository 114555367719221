import React from 'react';
import moment from 'moment';
import { RenderCurrency, RenderNumber } from '../../Utils/renderField/renderReadField';


const ApplyDetailValues = (props) => {

    const {
        item,
    } = props;

    return (
        <div className="w-100">
            <div className="d-flex flex-wrap">
                <div className="col-12 mb-2 d-flex justify-content-between align-items-center">
                    <span className="fw-bold">PERSONAL</span>
                    {
                        item && item.referral_code && (
                            <React.Fragment>
                                <label style={{ marginRight: '160px' }}>
                                    <span style={{ color: 'red' }}>Referral Code:</span>
                                    <span style={{ color: 'black' }}> {item.referral_code}</span>
                                </label>
                            </React.Fragment>
                        
                        )
                    }
                    
                </div>
                <div className="col-12 col-md-4 mb-2">
                    <strong>Full Name:</strong>
                    <span>&nbsp;{item.full_name}</span>
                </div>
                <div className="col-12 col-md-4 mb-2">
                    <strong>Email:</strong>
                    <span>&nbsp;{item.email}</span>
                </div>
                <div className="col-12 col-md-4 mb-2">
                    <strong>Phone Number:</strong>
                    <span>&nbsp;<RenderNumber value={item.phone} formatTemplate="(###) ###-####"/></span>
                </div>
                <div className="col-12 col-md-5 mb-2">
                    <strong>Address:</strong>
                    <span>&nbsp;{item.address}{item.apt ? ` ${item.apt}` : ''}</span>
                </div>
                <div className="col-12 col-md-3 mb-2">
                    <strong>City:</strong>
                    <span>&nbsp;{item.city}</span>
                </div>
                <div className="col-12 col-md-3 mb-2">
                    <strong>State:</strong>
                    <span>&nbsp;{item.state}</span>
                </div>
                <div className="col-12 col-md-5 mb-2">
                    <strong>DOB:</strong>
                    <span>&nbsp;{item.birth ? moment(item.birth).format('MM/DD/YYYY') : item.birth}</span>
                </div>
                <div className="col-12 col-md-5 mb-2">
                    <strong>Education:</strong>
                    <span>&nbsp;{item.education && item.education.label ? item.education.label : item.education}</span>
                </div>
            </div>
            <div className="d-flex flex-wrap mt-5">
                <div className="col-12 mb-2">
                    <span>JOB PREFERENCES</span>
                </div>
                <div className="col-12 col-md-3 mb-2">
                    <strong>Position:</strong>
                    <span>&nbsp;{item.position && item.position.label ? item.position.label : item.position}</span>
                </div>
                <div className="col-12 col-md-3 mb-2">
                    <strong>Desired Income:</strong>
                    <span>&nbsp;<RenderCurrency value={item.month_salary}/>/mo</span>
                </div>
                <div className="col-12 col-md-3 mb-2">
                    <strong>Availability:</strong>
                    <span>&nbsp;{item.availability && item.availability.label ? item.availability.label : item.availability}</span>
                </div>
                <div className="col-12 col-md-3 mb-2">
                    <strong>Shift:</strong>
                    <span>&nbsp;{item.shift && item.shift.label ? item.shift.label : item.shift}</span>
                </div>
                {item.custom_shift ? (
                    <div className="col-12 col-md-3 mb-2">
                        <strong>Custom Shift:</strong>
                        <span>&nbsp;{item.custom_shift}</span>
                    </div>
                ) : ''}
                <div className="col-12 col-md-3 mb-2">
                    <strong>Start Date:</strong>
                    <span>&nbsp;{item.start_date ? moment(item.start_date).format('MM/DD/YYYY') : ''}</span>
                </div>
                <div className="col-12 col-md-5 mb-2">
                    <strong>Worked with us before?</strong>
                    <span>&nbsp;{item.work_before}</span>
                </div>
                <div className="col-12 mb-2">
                    <strong>Reason for leaving:</strong>
                    <span>&nbsp;{item.reason_leaving && item.reason_leaving.label ? item.reason_leaving.label : item.reason_leaving}</span>
                </div>
            </div>
            <div className="d-flex flex-wrap mt-5">
                <div className="col-12 mb-2">
                    <span>DRIVER LICENSE</span>
                </div>
                <div className="col-12 col-md-3 mb-2">
                    <strong>Type:</strong>
                    <span>&nbsp;{item.dl_type && item.dl_type.label ? item.dl_type.label : item.dl_type}</span>
                </div>
                <div className="col-12 col-md-3 mb-2">
                    <strong>Issue State:</strong>
                    <span>&nbsp;{item.issue_state}</span>
                </div>
                <div className="col-12 col-md-3 mb-2">
                    <strong>License Number:</strong>
                    <span>&nbsp;{item.license_number}</span>
                </div>
                <div className="col-12 col-md-3 mb-2">
                    <strong>Expiration Date:</strong>
                    <span>&nbsp;{item.license_expiration ? moment(item.license_expiration).format('MM/DD/YYYY') : ''}</span>
                </div>
                {item.dot_license && item.dot_license.length > 0 ? (
                    item.dot_license.map((license, index) => (
                        <React.Fragment key={index}>
                            <div className="col-12 col-md-3 mb-2">
                                <strong>Type:</strong>
                                <span>&nbsp;{license.dl_type && license.dl_type.label ? license.dl_type.label : license.dl_type}</span>
                            </div>
                            <div className="col-12 col-md-3 mb-2">
                                <strong>Issue State:</strong>
                                <span>&nbsp;{license.issue_state}</span>
                            </div>
                            <div className="col-12 col-md-3 mb-2">
                                <strong>License Number:</strong>
                                <span>&nbsp;{license.license}</span>
                            </div>
                            <div className="col-12 col-md-3 mb-2">
                                <strong>Expiration Date:</strong>
                                <span>&nbsp;{license.expiration ? moment(item.expiration).format('MM/DD/YYYY') : ''}</span>
                            </div>
                        </React.Fragment>
                    ))
                ) : ''}
                <div className="col-12 mb-2">
                    <strong>Vehicles Driven:</strong>
                    <span>
                        &nbsp;{item.vehicle_type ? item.vehicle_type.label : ''}
                    </span>
                </div>
                <div className="col-12 mb-2">
                    <span>Have you had any accidents in the past 3 years?</span>
                    <strong>&nbsp;{item.accidents && item.accidents.yes == '1' ? 'YES' : 'NO'}</strong>
                </div>
                {item.accidents && item.accidents.yes == '1'
                    ? <div className="col-12 mb-2">
                        <strong>{item.accidents && item.accidents.reason}</strong>
                    </div>
                    : ''
                }
                <div className="col-12 mb-2">
                    <span>Have you had any traffic violations in the past 3 years?</span>
                    <strong>&nbsp;{item.traffic_violations && item.traffic_violations.yes == '1' ? 'YES' : 'NO'}</strong>
                </div>
                {item.traffic_violations && item.traffic_violations.yes == '1'
                    ? <div className="col-12 mb-2">
                        <strong>{item.traffic_violations && item.traffic_violations.reason}</strong>
                    </div>
                    : ''
                }
                <div className="col-12 mb-2">
                    <span>Have you ever been denied a license or permit to operate a vehicle?</span>
                    <strong>&nbsp;{item.denied_license && item.denied_license.yes == '1' ? 'YES' : 'NO'}</strong>
                </div>
                {item.denied_license && item.denied_license.yes == '1'
                    ? <div className="col-12 mb-2">
                        <strong>{item.denied_license && item.denied_license.reason}</strong>
                    </div>
                    : ''
                }
                <div className="col-12 mb-2">
                    <span>Has you license ever been suspended or revoked:</span>
                    <strong>&nbsp;{item.suspended_license && item.suspended_license.yes == '1' ? 'YES' : 'NO'}</strong>
                </div>
                {item.suspended_license && item.suspended_license.yes == '1'
                    ? <div className="col-12 mb-2">
                        <strong>{item.suspended_license && item.suspended_license.reason}</strong>
                    </div>
                    : ''
                }
            </div>
            <div className="d-flex flex-wrap mt-5">
                <div className="col-12 mb-2">
                    <span>EMPLOYMENT HISTORY</span>
                </div>
                {item.employment_history && item.employment_history.length > 0 ? (
                    item.employment_history.map((eh, i) => (
                        <React.Fragment key={i}>
                            <div className="col-12 mb-2">
                                <strong>Company Name:</strong>
                                <span>&nbsp;{eh.company_name}</span>
                            </div>
                            <div className="col-12 col-md-4 mb-2">
                                <strong>Start Date:</strong>
                                <span>&nbsp;{eh.company_start ? moment(eh.company_start).format('MM/DD/YYYY') : ''}</span>
                            </div>
                            <div className="col-12 col-md-4 mb-2">
                                <strong>End Date:</strong>
                                <span>&nbsp;{eh.company_end ? moment(eh.company_end).format('MM/DD/YYYY') : ''}</span>
                            </div>
                            <div className="col-12 col-md-4 mb-2">
                                <strong>Position Held:</strong>
                                <span>&nbsp;{eh.company_position}</span>
                            </div>
                            <div className="col-12 mb-2">
                                <strong>Reason for Leaving:</strong>
                                <span>&nbsp;{eh.company_reason_leaving}</span>
                            </div>
                        </React.Fragment>
                    ))
                ) : (
                    <div className="col-12 mb-2">
                        No Data
                    </div>
                )}
            </div>
            <div className="d-flex flex-wrap mt-5">
                <div className="col-12 mb-2">
                    <span>ADDITIONAL INFORMATION</span>
                </div>
                <div className="col-12 mb-2">
                    <span>Have you ever been convicted of a felony?</span>
                    <strong>&nbsp;{item.convicted && item.convicted.yes == '1' ? 'YES' : 'NO'}</strong>
                </div>
                {item.convicted && item.convicted.yes === '1'
                    ? <div className="col-12 mb-2">
                        <strong>{item.convicted.reason}</strong>
                    </div>
                    : ''
                }
                <div className="col-12 mb-2">
                    <span>Do you have any physical limitations that affect your ability to operate a vehicle or lift bags?</span>
                    <strong>&nbsp;{item.physical_limitations && item.physical_limitations.yes === '1' ? 'YES' : 'NO'}</strong>
                </div>
                {item.physical_limitations && item.physical_limitations.yes === '1'
                    ? <div className="col-12 mb-2">
                        <strong>{item.physical_limitations.reason}</strong>
                    </div>
                    : ''
                }
                <div className="col-12 mb-2">
                    <span>How did you hear about CONNECT?</span>
                </div>
                <div className="col-12 mb-2">
                    <strong>&nbsp;{item.hear_about}</strong>
                </div>
                <div className="col-12 mb-2">
                    <span>Why do you want to work with CONNECT?</span>
                </div>
                <div className="col-12 mb-2">
                    <strong>&nbsp;{item.want_work}</strong>
                </div>
            </div>
            <div className="d-flex flex-wrap mt-5">
                <div className="col-12 mb-2">
                    <span>REFERENCES</span>
                </div>
                {item.references && item.references.length > 0 ? (
                    item.references.map((re, i) => (
                        <React.Fragment key={i}>
                            <div className="col-12 col-md-6">
                                <div className="d-flex mb-2">
                                    <strong>Name:</strong>
                                    <span>&nbsp;{re.company}</span>
                                </div>
                                <div className="d-flex mb-2">
                                    <strong>Email:</strong>
                                    <span>&nbsp;{re.relationship}</span>
                                </div>
                                <div className="d-flex mb-2">
                                    <strong>Phone:</strong>
                                    <span>&nbsp;<RenderNumber value={re.phone} formatTemplate="(###) ###-####"/></span>
                                </div>
                            </div>
                        </React.Fragment>
                    ))
                ) : (
                    <div className="col-12 mb-2">
                        No Data
                    </div>
                )}
            </div>
            <div className="d-flex flex-wrap mt-5">
                <div className="col-12 mb-2">
                    <span>DOT ADDRESS HISTORY</span>
                </div>
                {item.dot_addresses && item.dot_addresses.length > 0 ? (
                    item.dot_addresses.map((da, i) => (
                        <React.Fragment key={i}>
                            <div className="col-12 col-md-6 mb-2">
                                <strong>Address</strong>
                                <span>&nbsp;{da.address}{da.apt ? ` ${da.apt}` : ''}</span>
                            </div>
                            <div className="col-12 col-md-2 mb-2">
                                <strong>City</strong>
                                <span>&nbsp;{da.city}</span>
                            </div>
                            <div className="col-12 col-md-2 mb-2">
                                <strong>State</strong>
                                <span>&nbsp;{da.state}</span>
                            </div>
                        </React.Fragment>
                    ))
                ) : (
                    <div className="col-12 mb-2">
                        No Data
                    </div>
                )}
            </div>
        </div>
    )
}

export default ApplyDetailValues;
